import React, { useEffect, useRef, useState } from "react";
import {
  Card,
  CardBody,
  Form,
  FormInput,
  FormGroup,
  Button,
  FormTextarea,
  FormSelect,
} from "shards-react";
import "../add-new-album/editorStyle.css";
import { useNavigate, useParams } from "react-router-dom";
import { CreateScene, EditScene, fetchSingleScene } from "../../Api/helpers";

const SceneEditor = () => {
  // State variables for form inputs
  const navigate = useNavigate();
  const { id } = useParams();
  const [formData, setFormData] = useState({
    name: "",
    order: ""
  });
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  // const [selectedAudio, setSelectedAudio] = useState(null);
  const [audioSrc, setAudioSrc] = useState(null);
  const [blobedAudioSrc, setBlobedAudioSrc] = useState(null);
  const [count, setCount] = useState(null)
  const audioInputRef = useRef(null);
  const fileInputRef = useRef(null);
  const [errors, setErrors] = useState({
    imageError: "",
    audioError: "",
  });

  useEffect(() => {
    if (errors) {
      const timer = setTimeout(() => {
        setErrors((prevState) => ({
          ...prevState,
          audioError: "",
          imageError: "",
        }));
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [errors]);

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isLoading) return;

    if (!audioSrc) {
      setErrors((prevState) => ({
        ...prevState,
        audioError: "Audio is required",
      }));
      return;
    }
    if (!selectedFile) {
      setErrors((prevState) => ({
        ...prevState,
        imageError: "Image is required",
      }));
      return;
    }
    setIsLoading(true);
    const body = new FormData();
    body.append("name", formData?.name);
    formData?.order && body.append("order", formData?.order);
    if (audioSrc) {
      body.append("file_path", audioSrc);
    }
    // console.log("selectedFile", selectedFile);
    if (selectedFile) {
      body.append("image", selectedFile);
    }
    // console.log(formData, body, image, selectedFile);
    try {
      let response;
      if (id) {
        response = await EditScene(body, id);
      } else {
        response = await CreateScene(body);
      }
      console.log(response?.data);
      if (response?.data) {
        navigate(-1);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const fetchData = async () => {
    try {
      const response = await fetchSingleScene(id);
      setCount(response?.data?.data?.count)
      const sceneData = response?.data?.data?.sceneData;
      setFormData({
        name: sceneData?.name,
        order:sceneData?.order
      });
      setAudioSrc(sceneData.file_path);
      setSelectedFile(sceneData?.image);
      // audioInputRef.current.value = sceneData?.file_path;
    } catch (error) {
      console.error("Error fetching track data:", error);
    }
  };

  useEffect(() => {
    if (id) {
      fetchData();
    }
  }, [id]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
    setSelectedFile(file);
  };
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  };
  const handleAudioChange = (event) => {
    const file = event.target.files[0];
    console.log("audio file", file);
    if (file) {
      const audioURL = URL.createObjectURL(file);
      console.log("audiourl", audioURL);
      setBlobedAudioSrc(audioURL);
      setAudioSrc(file);
    }
  };

  return (
    <Card small className="mb-3">
      <CardBody>
        <Form onSubmit={handleSubmit}>
          <div className="add-new-post row">
            <FormGroup className="col-md-6">
              <label htmlFor="name">Name</label>
              <FormInput
                name="name"
                value={formData?.name}
                onChange={handleChange}
                placeholder="Name"
                required
              />
            </FormGroup>
            {id && <FormGroup className="col-md-6">
              <label htmlFor="order">Order</label>
              <FormInput
                name="order"
                value={formData?.order}
                onChange={handleChange}
                placeholder="Order"
                required = {id ? true : false}
                type = "Number"
                min="1"
                max={count}
              />
            </FormGroup>}
            <FormGroup className="col-md-6">
              <label htmlFor="audio">Upload Audio</label>
              <input
                type="file"
                accept="audio/*"
                className="form-control"
                onChange={handleAudioChange}
                ref={audioInputRef}
                name="selectedAudio"
              />
              {audioSrc && (
                <audio
                  controls
                  className="mt-4"
                  key={blobedAudioSrc || audioSrc}
                >
                  <source src={blobedAudioSrc || audioSrc} type="audio/mpeg" />
                  Your browser does not support the audio element.
                </audio>
              )}
              {errors.audioError && (
                <p style={{ color: "red" }}>{errors.audioError}</p>
              )}
            </FormGroup>
            <FormGroup className="col-md-6 mt-4">
              <div
                className="file-upload-container"
                onClick={() => fileInputRef.current.click()}
                style={
                  selectedFile &&
                  // Object.keys(selectedFile).length === 1 &&
                  !image
                    ? {
                        backgroundImage: `url(${selectedFile})`,
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                      }
                    : {
                        backgroundImage: `url(${image})`,
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                      }
                }
              >
                {!selectedFile && (
                  <span
                    className="download-icon"
                    role="img"
                    aria-label="download icon"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="black"
                      className="upload-button "
                    >
                      <g clipPath="url(#clip0_3_407)">
                        <path
                          d="M18 15V18H6V15H4V18C4 19.1 4.9 20 6 20H18C19.1 20 20 19.1 20 18V15H18ZM17 11L15.59 9.59L13 12.17V4H11V12.17L8.41 9.59L7 11L12 16L17 11Z"
                          fill="black"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_3_407">
                          <rect width={24} height={24} fill="black" />
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                )}
                {!selectedFile && (
                  <p className="upload-message">Drop an image here</p>
                )}
                <input
                  type="file"
                  accept="image/*"
                  className="file-input"
                  onChange={handleFileChange}
                  ref={fileInputRef}
                  name="selectedFile"
                />

                {/* {!selectedFile && (
                <p className="selected-file">
                  Selected Image: <strong>{selectedFile?.name}</strong>
                </p>
              )} */}
              </div>
              {errors.imageError && (
                <p style={{ color: "red" }}>{errors.imageError}</p>
              )}
            </FormGroup>
          </div>
          <Button
            theme="primary"
            type="submit"
            className="custom-button"
            disabled={isLoading}
          >
            {isLoading ? "Submitting..." : "Submit"}
          </Button>
        </Form>
      </CardBody>
    </Card>
  );
};

export default SceneEditor;
