import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  FormInput,
  Row,
} from "shards-react";
import PageTitle from "../../components/common/PageTitle";
import { LoginAdmin } from "../../Api/helpers";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../Context/AuthContext";

export const Login = () => {
  const navigate = useNavigate();
  const { isAuthenticated, login } = useAuth();
  const initialFormData = {
    email: "",
    password: "",
  };
  //   if (isAuthenticated) {
  //     navigate("/albums");
  //   }
  // useEffect(() => {
  //   if (isAuthenticated) {
  //     navigate("/categories");
  //   }
  // }, [isAuthenticated]);

  const [formData, setFormData] = useState(initialFormData);
  const [invalidData, setInvalidData] = useState(false);
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  };
  useEffect(() => {
    if (invalidData) {
      const timer = setTimeout(() => {
        setInvalidData(false);
      }, 2000);
      return () => clearTimeout(timer); 
    }
  }, [invalidData]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("the form data received: ", formData);
    let response = await LoginAdmin(formData);
    setFormData(initialFormData);
    console.log("response fron login api: ", response?.data);
    if (response?.data?.isSuccess) {
      localStorage.setItem("token", response?.data?.data);
      login(response?.data?.data);
      navigate("/categories");
    } else {
      //   alert("invalid credentials!");
      setInvalidData(true);
    }
  };
  return (
    <Container
      fluid
      className="main-content-container px-4"
      style={{ width: "400px", marginTop: "100px" }}
    >
      {/* Page Header */}
      <Row
        noGutters
        className="page-header py-4 justify-content-between custom-page-header"
      >
        <div style={{ display: "block", margin: "auto" }}>
          <img
            id="main-logo"
            className="d-inline-block align-top mr-1"
            style={{ maxWidth: "50px" }}
            src={require("../../images/Logo_v2.svg").default}
            alt="Plenum Dashboard"
          />
          <span
            className="d-none d-md-inline ml-1"
            style={{ fontSize: "30px", color: "black" }}
          >
            Plenum
          </span>
        </div>
      </Row>

      <Row>
        <Col>
          <Card>
            <CardBody className="p-0 pb-3 overflow-auto">
              <h6
                style={{
                  marginTop: "30px",
                  textAlign: "center",
                  fontSize: "24px",
                  fontWeight: "500",
                  color: "black",
                }}
              >
                Sign in
              </h6>
              <Form onSubmit={handleSubmit}>
                <FormGroup
                  className="col-md-12"
                  style={{ display: "block", margin: "auto" }}
                >
                  <label htmlFor="name">Email</label>
                  <FormInput
                    name="email"
                    value={formData?.email}
                    onChange={handleChange}
                    placeholder="Email"
                    required
                  />
                </FormGroup>
                <FormGroup
                  className="col-md-12 mt-2"
                  style={{ display: "block", margin: "auto" }}
                >
                  <label htmlFor="name">Password</label>
                  <FormInput
                    name="password"
                    value={formData?.password}
                    onChange={handleChange}
                    placeholder="Password"
                    type="password"
                    required
                  />
                </FormGroup>
                <Button
                  type="submit"
                  theme="primary"
                  className="col-md-11 my-4"
                  style={{ display: "block", margin: "auto" }}
                >
                  Login
                </Button>
                <div style={{textAlign: "center", color: "red"}}>{invalidData ? "Invalid Credentials" : ""}</div>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
