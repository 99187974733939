// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.file-upload-container {
    border-radius: 10px;
    border: 2px dashed var(--dark);
    width: 250px;
    height: 250px;
    /* border: 2px solid #ccc; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    /* background-color: #224957; */
    color: var(--dark);
  }
  
  .download-icon {
    font-size: 36px;
  }
  
  .upload-message {
    margin-top: 10px;
  }
  
  .file-input {
    display: none;
  }
  
  .upload-button {
    margin-top: 10px;
  }
  
  .selected-file {
    margin-top: 10px;
  }
  .SvgDiv {
    position: relative;
  }
  .SvgDiv svg {
    position: absolute;
    right: 5px;
    bottom: 0px;
  }
  .btn {
    border-radius: 10px !important;
  }
  .card-body form .custom-button {
    font-size: 1rem !important;
    padding: 0.75rem 2rem !important;
  }
  .custom-checkbox label{
    padding-right: 10px;
  }`, "",{"version":3,"sources":["webpack://./src/components/add-new-album/editorStyle.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,8BAA8B;IAC9B,YAAY;IACZ,aAAa;IACb,4BAA4B;IAC5B,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,eAAe;IACf,+BAA+B;IAC/B,kBAAkB;EACpB;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,aAAa;EACf;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,gBAAgB;EAClB;EACA;IACE,kBAAkB;EACpB;EACA;IACE,kBAAkB;IAClB,UAAU;IACV,WAAW;EACb;EACA;IACE,8BAA8B;EAChC;EACA;IACE,0BAA0B;IAC1B,gCAAgC;EAClC;EACA;IACE,mBAAmB;EACrB","sourcesContent":[".file-upload-container {\n    border-radius: 10px;\n    border: 2px dashed var(--dark);\n    width: 250px;\n    height: 250px;\n    /* border: 2px solid #ccc; */\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    cursor: pointer;\n    /* background-color: #224957; */\n    color: var(--dark);\n  }\n  \n  .download-icon {\n    font-size: 36px;\n  }\n  \n  .upload-message {\n    margin-top: 10px;\n  }\n  \n  .file-input {\n    display: none;\n  }\n  \n  .upload-button {\n    margin-top: 10px;\n  }\n  \n  .selected-file {\n    margin-top: 10px;\n  }\n  .SvgDiv {\n    position: relative;\n  }\n  .SvgDiv svg {\n    position: absolute;\n    right: 5px;\n    bottom: 0px;\n  }\n  .btn {\n    border-radius: 10px !important;\n  }\n  .card-body form .custom-button {\n    font-size: 1rem !important;\n    padding: 0.75rem 2rem !important;\n  }\n  .custom-checkbox label{\n    padding-right: 10px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
