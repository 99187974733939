import React from "react";
// import { Navigate } from "react-router-dom";

// Layout Types
import DefaultLayout from "./layouts/Default.js";

// Route Views
import UserProfileLite from "./views/UserProfileLite";
import AddNewAlbum from "./views/Albums/AddNewAlbum.js";
import Errors from "./views/Errors";
import AlbumTableList from "./views/Albums/AlbumTableList.js";
import CategoryTableList from "./views/Categories/CategoryTableList.js";
import CategoryUpsert from "./views/Categories/CategoryUpsert.js";
import TrackTableList from "./views/Tracks/TrackTableList.js";
import TrackUpsert from "./views/Tracks/TrackUpsert.js";
import { PrivacyPolicy } from "./views/PrivacyPolicy/PrivacyPolicy.js";
import { TermsAndConditions } from "./views/TermsAndConditions/TermsAndConditions.js";
import AddAuthor from "./views/Author/AddAuthor.js";
import Author from "./views/Author/Author.js";
import { Login } from "./views/Login/Login.js";
import ProtectedRoute from "./components/ProtectedRoute.js";
import { AddScenes } from "./views/Scenes/AddScenes.js";
import { Scenes } from "./views/Scenes/Scenes.js";

const routes = [
  {
    path: "/login",
    element: <Login />
  },
  {
    path: "/",
    element: <ProtectedRoute element={ <DefaultLayout><CategoryTableList /></DefaultLayout>} />
  },
  {
    path: "/albums",
    element: <ProtectedRoute element={<DefaultLayout><AlbumTableList /></DefaultLayout>} />
  },
  {
    path: "/add-new-album",
    element: <ProtectedRoute element={<DefaultLayout><AddNewAlbum /></DefaultLayout>} />
  },
  {
    path: "/edit-album/:id",
    element: <ProtectedRoute element={ <DefaultLayout><AddNewAlbum /></DefaultLayout>} />
  },
  {
    path: "/categories",
    element: <ProtectedRoute element={ <DefaultLayout><CategoryTableList /></DefaultLayout>} />
  },
  {
    path: "/categories/:id",
    element: <ProtectedRoute element={ <DefaultLayout><CategoryTableList /></DefaultLayout>} />
  },
  {
    path: "/add-new-category",
    element: <ProtectedRoute element={ <DefaultLayout><CategoryUpsert /></DefaultLayout>} />
  },
  {
    path: "/edit-category/:id",
    element: <ProtectedRoute element={ <DefaultLayout><CategoryUpsert /></DefaultLayout>} />
  },
  {
    path: "/tracks",
    element: <ProtectedRoute element={ <DefaultLayout><TrackTableList /></DefaultLayout>} />
  },
  {
    path: "/tracks/:id",
    element: <ProtectedRoute element={ <DefaultLayout><TrackTableList /></DefaultLayout>} />
  },
  {
    path: "/add-new-track",
    element: <ProtectedRoute element={ <DefaultLayout><TrackUpsert /></DefaultLayout>} />
  },
  {
    path: "/edit-track/:id",
    element: <ProtectedRoute element={ <DefaultLayout><TrackUpsert /></DefaultLayout>} />
  },
  {
    path: "/errors",
    element: <ProtectedRoute element={<DefaultLayout><Errors /></DefaultLayout>} />
  },
  {
    path: "/user-profile-lite",
    element: <ProtectedRoute element={<DefaultLayout><UserProfileLite /></DefaultLayout>} />
  },
  {
    path: "/privacy-policy",
    element: <ProtectedRoute element={<DefaultLayout><PrivacyPolicy/></DefaultLayout>} />
  },
  {
    path: "/terms-and-conditions",
   element: <ProtectedRoute element={<DefaultLayout><TermsAndConditions/></DefaultLayout>} />
  },
  {
    path: "/author",
    element: <ProtectedRoute element={<DefaultLayout><Author/></DefaultLayout>} />
  },
  {
    path: "/add-new-author",
    element: <ProtectedRoute element={<DefaultLayout><AddAuthor/></DefaultLayout>} />
  },
  {
    path: "/edit-author/:id",
    element: <ProtectedRoute element={ <DefaultLayout><AddAuthor /></DefaultLayout>} />
  },
  {
    path: "/scenes",
    element: <ProtectedRoute element={<DefaultLayout><Scenes/></DefaultLayout>} />
  },
  {
    path: "/add-scenes",
    element: <ProtectedRoute element={<DefaultLayout><AddScenes/></DefaultLayout>} />
  },
  {
    path: "/edit-scenes/:id",
    element: <ProtectedRoute element={<DefaultLayout><AddScenes/></DefaultLayout>} />
  },
];

export default routes;
