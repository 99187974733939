// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::-webkit-scrollbar {
    width: 6px; /* Width of the vertical scrollbar */
    /* height: 8px;  */
  }
  ::-webkit-scrollbar:horizontal {
    height: 6px;
  }
  ::-webkit-scrollbar-thumb {
    background: #888; /* Color of the scrollbar thumb */
  }
  .custom-editor-class {
    border: 1px solid #f3f3f3;
    padding: 10px;
    border-radius: 4px;
    min-height: 300px;
  }`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,UAAU,EAAE,oCAAoC;IAChD,kBAAkB;EACpB;EACA;IACE,WAAW;EACb;EACA;IACE,gBAAgB,EAAE,iCAAiC;EACrD;EACA;IACE,yBAAyB;IACzB,aAAa;IACb,kBAAkB;IAClB,iBAAiB;EACnB","sourcesContent":["::-webkit-scrollbar {\n    width: 6px; /* Width of the vertical scrollbar */\n    /* height: 8px;  */\n  }\n  ::-webkit-scrollbar:horizontal {\n    height: 6px;\n  }\n  ::-webkit-scrollbar-thumb {\n    background: #888; /* Color of the scrollbar thumb */\n  }\n  .custom-editor-class {\n    border: 1px solid #f3f3f3;\n    padding: 10px;\n    border-radius: 4px;\n    min-height: 300px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
