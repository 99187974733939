import React, { useEffect, useRef, useState } from "react";
import {
  Card,
  CardBody,
  Form,
  FormInput,
  FormGroup,
  Button,
  FormTextarea,
  FormSelect,
  FormCheckbox,
} from "shards-react";
import "../add-new-album/editorStyle.css";
import { useNavigate, useParams } from "react-router-dom";
import {
  CreateTrack,
  EditTrack,
  fetchAllAlbums,
  fetchAllAuthors,
  fetchAllCategories,
  fetchSingleTrack,
} from "../../Api/helpers";
import { toast } from "react-toastify";

const TrackEditor = () => {
  // State variables for form inputs
  const navigate = useNavigate();
  const { id } = useParams();
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    album: "",
    category: "",
    author: "",
    accessType: "",
    order: "",
    isFeatured: false
  });
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [authors, setAuthors] = useState([]);
  const [albums, setAlbums] = useState([]);
  // const [selectedAudio, setSelectedAudio] = useState(null);
  const [audioSrc, setAudioSrc] = useState(null);
  const [blobedAudioSrc, setBlobedAudioSrc] = useState(null);
  const audioInputRef = useRef(null);
  const fileInputRef = useRef(null);
  const [audioDuration, setAudioDuration] = useState(null);
  const [count, setCount] = useState(null);
  const [errors, setErrors] = useState({
    imageError: "",
    audioError: "",
  });
  const [addToAlbum, setAddToAlbum] = useState(false);
  const [categories, setCategories] = useState([]);
  // const [isAddToAlbum, setIsAddToAlbum] = useState(true);

  // console.log("idid", id);

  useEffect(() => {
    if (errors) {
      const timer = setTimeout(() => {
        setErrors((prevState) => ({
          ...prevState,
          audioError: "",
          imageError: "",
        }));
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [errors]);

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isLoading) return;
    if (addToAlbum) {
      if (formData?.album === "") {
        toast.error("Album name is required.");
      }
    } else {
      if (formData?.category === "") {
        toast.error("Category name is required.");
      }
    }
    if (!audioSrc) {
      setErrors((prevState) => ({
        ...prevState,
        audioError: "Audio is required",
      }));
      return;
    }
    if (!selectedFile) {
      setErrors((prevState) => ({
        ...prevState,
        imageError: "Image is required",
      }));
      return;
    }
    setIsLoading(true);
    const body = new FormData();
    body.append("title", formData?.name);
    formData?.order && body.append("order", formData?.order);
    body.append("description", formData?.description);
    body.append("is_featured", formData?.isFeatured);
    if (formData?.album != "" && addToAlbum) {
      body.append("album_id", formData?.album);
    } else {
      body.append("category_id", formData?.category);
    }

    if (formData?.author != "") {
      body.append("author_id", formData?.author);
    }
    body.append("is_paid", formData?.accessType === "1");
    if (audioDuration) body.append("duration", audioDuration);
    if (audioSrc) {
      body.append("file_path", audioSrc);
    }
    console.log("selectedFile", selectedFile);
    if (selectedFile) {
      body.append("image", selectedFile);
    }
    console.log(formData, body, image, selectedFile);
    try {
      let response;
      if (id) {
        response = await EditTrack(body, id);
      } else {
        response = await CreateTrack(body);
      }
      console.log(response?.data);
      if (response?.data) {
        navigate(-1);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const fetchData = async () => {
    try {
      const response = await fetchSingleTrack(id);
      const trackData = response?.data?.data?.track;
      setCount(response?.data?.data?.count);
      setFormData({
        name: trackData?.title,
        description: trackData?.description,
        album: trackData?.album_id,
        author: trackData?.author_id,
        accessType: trackData?.is_paid ? "1" : "0",
        order: trackData?.order,
        category: trackData?.category_id,
        isFeatured: trackData?.is_featured,
      });
      if (trackData?.album_id) {
        setAddToAlbum(true);
      } else {
        setAddToAlbum(false);
      }
      setAudioSrc(trackData?.file_path);
      // console.log("trackData?.image", trackData?.image);
      setSelectedFile(trackData?.image);
      // audioInputRef.current.value = trackData?.file_path;
    } catch (error) {
      console.error("Error fetching track data:", error);
    }
  };

  useEffect(() => {
    if (id) {
      fetchData();
    }
  }, [id]);
  const fetchAlbums = async () => {
    const response = await fetchAllAlbums();
    if (response?.data?.isSuccess) {
      setAlbums(response?.data?.data?.rows);
    }
  };

  // Function to fetch authors data
  const fetchAuthors = async () => {
    const response = await fetchAllAuthors();
    if (response?.data?.isSuccess) {
      setAuthors(response?.data?.data?.rows);
    }
  };
  useEffect(() => {
    fetchAuthors();
    fetchAlbums();
  }, []);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
      setSelectedFile(file);
    }
  };
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleAudioChange = (event) => {
    const file = event.target.files[0];
    console.log("audio file", file);

    if (file) {
      const audioURL = URL.createObjectURL(file);
      console.log("audioURL", audioURL);
      setBlobedAudioSrc(audioURL);
      setAudioSrc(file);

      var audio = document.createElement("audio");
      audio.src = audioURL;

      // Wait for the metadata to be loaded before accessing the duration
      audio.addEventListener("loadedmetadata", () => {
        var duration = audio.duration;
        console.log("Duration: " + duration + " seconds");
        setAudioDuration(parseInt(duration));
      });
    }
  };
  const fetchCategories = async (categoryId) => {
    const response = await fetchAllCategories();
    if (response?.data?.isSuccess) {
      const data = response?.data?.data?.rows;
      const filteredData = data.filter((item) => item.id != categoryId);
      setCategories(filteredData);
    }
  };

  useEffect(() => {
    fetchCategories(id);
  }, []);
  return (
    <Card small className="mb-3">
      <CardBody>
        <Form onSubmit={handleSubmit}>
          <div className="add-new-post row">
            <FormGroup className="col-md-6">
              <label htmlFor="name">Name</label>
              <FormInput
                name="name"
                value={formData?.name}
                onChange={handleChange}
                placeholder="Name"
                required
              />
            </FormGroup>
            <FormGroup className="col-md-6 mt-2 d-flex align-items-center">
              <input
                style={{ marginRight: "10px" }}
                type="checkbox"
                name="isFeatured"
                checked={addToAlbum}
                disabled={id ? true : false}
                onChange={() => {
                  setAddToAlbum((prevState) => !prevState);
                  // setFormData({...formData, category: "", album: ""})
                }}
                className="mb-2"
                // required
              />
              <label htmlFor="accessType">Add to Album</label>
            </FormGroup>
            {addToAlbum ? (
              <FormGroup className="col-md-6">
                <label htmlFor="album">Album</label>
                <FormSelect
                  name="album"
                  value={formData?.album}
                  onChange={handleChange}
                  placeholder="Album"
                  disabled={id ? true : false}
                  // required={addToAlbum}
                >
                  <option value="" disabled>
                    Select an album
                  </option>
                  {albums?.map((album) => (
                    <option key={album?.id} value={album?.id}>
                      {album?.title}
                    </option>
                  ))}
                </FormSelect>
              </FormGroup>
            ) : (
              <FormGroup className="col-md-6">
                <label htmlFor="category">Category</label>
                <FormSelect
                  name="category"
                  value={formData?.category}
                  onChange={handleChange}
                  placeholder="Category"
                  disabled={id ? true : false}
                  // required={!addToAlbum}
                >
                  <option value="" disabled>
                    Select a category
                  </option>
                  {categories?.map(
                    (category) =>
                      category?.parent_category_id && (
                        <option key={category?.id} value={category?.id}>
                          {category?.name}
                        </option>
                      )
                  )}
                </FormSelect>
              </FormGroup>
            )}
            {/* <FormGroup className="col-md-6">
              <label htmlFor="album">Album</label>
              <FormSelect
                name="album"
                value={formData?.album}
                onChange={handleChange}
                placeholder="Album"
                // required
              >
                <option value="" disabled>
                  Select a album
                </option>
                {albums?.map((album) => (
                  <option key={album?.id} value={album?.id}>
                    {album?.title}
                  </option>
                ))}
              </FormSelect>
            </FormGroup> */}
            <FormGroup className="col-md-6">
              <label htmlFor="accessType">Access Type</label>
              <FormSelect
                name="accessType"
                value={formData?.accessType}
                onChange={handleChange}
                placeholder="Access Type"
                required
              >
                <option value="" disabled>
                  Select a access type
                </option>
                <option value={"0"}>Free</option>
                <option value={"1"}>Paid</option>
              </FormSelect>
            </FormGroup>
            <FormGroup className="col-md-6">
              <label htmlFor="author">Author</label>
              <FormSelect
                id="author"
                name="author"
                value={formData?.author}
                onChange={handleChange}
                placeholder="Author"
                // required
              >
                <option value="" disabled>
                  Select an author
                </option>
                {authors?.map((author) => (
                  <option key={author?.id} value={author?.id}>
                    {author?.name}
                  </option>
                ))}
              </FormSelect>
            </FormGroup>
            {/* Input for image upload */}
            <FormGroup className="col-md-6">
              <label htmlFor="description">Description</label>
              <FormTextarea
                name="description"
                type="textarea"
                value={formData?.description}
                onChange={handleChange}
                placeholder="Description"
                // required
              />
            </FormGroup>
            {id && (
              <FormGroup className="col-md-6">
                <label htmlFor="order">Order</label>
                <FormInput
                  name="order"
                  value={formData?.order}
                  onChange={handleChange}
                  placeholder="Order"
                  required={id ? true : false}
                  type="Number"
                  min="1"
                  max={count}
                />
              </FormGroup>
            )}
            <FormGroup className="col-md-6 mt-2 d-flex align-items-center">
              <input
                style={{ marginRight: "10px" }}
                type="checkbox"
                name="isFeatured"
                checked={formData?.isFeatured}
                onChange={handleChange}
                className="mb-2"
              />
              <label htmlFor="accessType">Featured</label>
            </FormGroup>
            <FormGroup className="col-md-6">
              <label htmlFor="audio">Upload Audio</label>
              <input
                type="file"
                accept="audio/*"
                className="form-control"
                onChange={handleAudioChange}
                ref={audioInputRef}
                name="selectedAudio"
              />
              {audioSrc && (
                <audio
                  id="myAudio"
                  controls
                  className="mt-4"
                  key={blobedAudioSrc || audioSrc}
                >
                  <source src={blobedAudioSrc || audioSrc} type="audio/mpeg" />
                  Your browser does not support the audio element.
                </audio>
              )}
              {errors.audioError && (
                <p style={{ color: "red" }}>{errors.audioError}</p>
              )}
            </FormGroup>
            <FormGroup className="col-md-6 mt-4">
              <div
                className="file-upload-container"
                onClick={() => fileInputRef.current.click()}
                style={
                  selectedFile &&
                  // Object.keys(selectedFile).length === 1 &&
                  !image
                    ? {
                        backgroundImage: `url(${selectedFile})`,
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                      }
                    : {
                        backgroundImage: `url(${image})`,
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                      }
                }
              >
                {!selectedFile && (
                  <span
                    className="download-icon"
                    role="img"
                    aria-label="download icon"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="black"
                      className="upload-button "
                    >
                      <g clipPath="url(#clip0_3_407)">
                        <path
                          d="M18 15V18H6V15H4V18C4 19.1 4.9 20 6 20H18C19.1 20 20 19.1 20 18V15H18ZM17 11L15.59 9.59L13 12.17V4H11V12.17L8.41 9.59L7 11L12 16L17 11Z"
                          fill="black"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_3_407">
                          <rect width={24} height={24} fill="black" />
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                )}
                {!selectedFile && (
                  <p className="upload-message">Drop an image here</p>
                )}
                <input
                  type="file"
                  accept="image/*"
                  className="file-input"
                  onChange={handleFileChange}
                  ref={fileInputRef}
                  name="selectedFile"
                />

                {/* {!selectedFile && (
                <p className="selected-file">
                  Selected Image: <strong>{selectedFile?.name}</strong>
                </p>
              )} */}
              </div>
              {errors.imageError && (
                <p style={{ color: "red" }}>{errors.imageError}</p>
              )}
            </FormGroup>
          </div>
          <Button
            theme="primary"
            type="submit"
            className="custom-button"
            disabled={isLoading}
          >
            {isLoading ? "Submitting..." : "Submit"}
          </Button>
        </Form>
      </CardBody>
    </Card>
  );
};

export default TrackEditor;
