import axios from "axios";

const config = {
  headers: {
    Authorization: `Bearer ${"token"}`,
  },
};

export const fetchAllCategories = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/category/all?limit=10000`,
      config
    );
    if (response?.data?.isSuccess) {
      return response;
    } else {
      console.error("Failed to fetch categories:", response?.data?.message);
    }
  } catch (error) {
    console.error("Error fetching categories:", error);
  }
};

export const fetchAllAuthors = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/author/all?limit=10000`,
      config
    );
    if (response?.data?.isSuccess) {
      return response;
    } else {
      console.error("Failed to fetch authors:", response?.data?.message);
    }
  } catch (error) {
    console.error("Error fetching authors:", error);
  }
};
export const fetchAllAlbums = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/album/all?limit=10000`,
      config
    );
    if (response?.data?.isSuccess) {
      return response;
    } else {
      console.error("Failed to fetch albums:", response?.data?.message);
    }
  } catch (error) {
    console.error("Error fetching albums:", error);
  }
};
export const fetchSingleAlbum = async (id) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/album/${id}`,
      config
    );
    if (response?.data?.isSuccess) {
      return response;
    } else {
      console.error("Failed to fetch album:", response?.data?.message);
    }
  } catch (error) {
    console.error("Error fetching album data:", error);
  }
};
export const CreateAlbum = async (body) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/album/create`,
      body,
      config
    );
    if (response?.data?.isSuccess) {
      return response;
    } else {
      console.error("Failed to create album:", response?.data?.message);
    }
  } catch (error) {
    console.error("Error creating album data:", error);
  }
};
export const EditAlbum = async (body, id) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_BASE_URL}/album/${id}`,
      body,
      config
    );
    if (response?.data?.isSuccess) {
      return response;
    } else {
      console.error("Failed to update album:", response?.data?.message);
    }
  } catch (error) {
    console.error("Error updating album data:", error);
  }
};
export const DeleteAlbum = async (id) => {
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_BASE_URL}/album/${id}`,
      config
    );
    if (response?.data?.isSuccess) {
      return response;
    } else {
      console.error("Failed to delete album:", response?.data?.message);
    }
  } catch (error) {
    console.error("Error deleting album data:", error);
  }
};
export const fetchAllTracks = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/track/all?limit=10000`,
      config
    );
    if (response?.data?.isSuccess) {
      return response;
    } else {
      console.error("Failed to fetch tracks:", response?.data?.message);
    }
  } catch (error) {
    console.error("Error fetching tracks:", error);
  }
};
export const fetchAllTracksByAlbumId = async (id) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/track/all-by-Album-id?id=${id}`,
      config
    );
    if (response?.data?.isSuccess) {
      return response;
    } else {
      console.error("Failed to fetch tracks:", response?.data?.message);
    }
  } catch (error) {
    console.error("Error fetching tracks:", error);
  }
};
export const CreateTrack = async (body) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/track/create`,
      body,
      config
    );
    if (response?.data?.isSuccess) {
      return response;
    } else {
      console.error("Failed to create track:", response?.data?.message);
    }
  } catch (error) {
    console.error("Error creating track data:", error);
  }
};
export const EditTrack = async (body, id) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_BASE_URL}/track/${id}`,
      body,
      config
    );
    if (response?.data?.isSuccess) {
      return response;
    } else {
      console.error("Failed to update track:", response?.data?.message);
    }
  } catch (error) {
    console.error("Error updating track data:", error);
  }
};
export const DeleteTrack = async (id) => {
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_BASE_URL}/track/${id}`,
      config
    );
    if (response?.data?.isSuccess) {
      return response;
    } else {
      console.error("Failed to delete track:", response?.data?.message);
    }
  } catch (error) {
    console.error("Error deleting track data:", error);
  }
};
export const fetchSingleTrack = async (id) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/track/${id}`,
      config
    );
    if (response?.data?.isSuccess) {
      return response;
    } else {
      console.error("Failed to fetch track:", response?.data?.message);
    }
  } catch (error) {
    console.error("Error fetching track data:", error);
  }
};
export const fetchSingleCategory = async (id) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/category/${id}`,
      config
    );
    if (response?.data?.isSuccess) {
      return response;
    } else {
      console.error("Failed to fetch category:", response?.data?.message);
    }
  } catch (error) {
    console.error("Error fetching category data:", error);
  }
};
export const CreateCategory = async (body) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/category/create`,
      body,
      config
    );
    if (response?.data?.isSuccess) {
      return response;
    } else {
      console.error("Failed to create category:", response?.data?.message);
    }
  } catch (error) {
    console.error("Error creating category data:", error);
  }
};
export const EditCategory = async (body, id) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_BASE_URL}/category/${id}`,
      body,
      config
    );
    if (response?.data?.isSuccess) {
      return response;
    } else {
      console.error("Failed to update category:", response?.data?.message);
    }
  } catch (error) {
    console.error("Error updating category data:", error);
  }
};
export const DeleteCategory = async (id) => {
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_BASE_URL}/category/${id}`,
      config
    );
    if (response?.data?.isSuccess) {
      return response;
    } else {
      console.error("Failed to delete category:", response?.data?.message);
    }
  } catch (error) {
    console.error("Error deleting category data:", error);
  }
};
export const getPolicy = async (category) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/policy/get`,
      {
        params: { category },
      }
    );
    console.log("the response is sssss ", response);
    if (response) {
      return response;
    } else {
      console.error("Failed to get Policy");
    }
  } catch (error) {
    console.error("Error getting policy data:", error);
  }
};
export const SavePolicy = async (content, category) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_BASE_URL}/policy/update`,
      content,
      category
    );
    console.log("the response is ", response);
    if (response) {
      return response;
    } else {
      console.error("Failed to save Policy");
    }
  } catch (error) {
    console.error("Error saving policy data:", error);
  }
};
export const CreateAuthor = async (body) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/author/create`,
      body,
      config
    );
    if (response?.data?.isSuccess) {
      return response;
    } else {
      console.error("Failed to create author:", response?.data?.message);
    }
  } catch (error) {
    console.error("Error creating author:", error);
  }
};
export const EditAuthor = async (body, id) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_BASE_URL}/author/${id}`,
      body,
      config
    );
    if (response?.data?.isSuccess) {
      return response;
    } else {
      console.error("Failed to update author:", response?.data?.message);
    }
  } catch (error) {
    console.error("Error updating author data:", error);
  }
};
export const fetchSingleAuthor = async (id) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/author/${id}`,
      config
    );
    if (response?.data?.isSuccess) {
      return response;
    } else {
      console.error("Failed to fetch author:", response?.data?.message);
    }
  } catch (error) {
    console.error("Error fetching author data:", error);
  }
};
export const LoginAdmin = async (body) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/user/login`,
      body
    );
    if (response?.data?.isSuccess) {
      return response;
    } else {
      console.error("Failed to Login Admin:", response?.data?.message);
    }
  } catch (error) {
    console.error("Error Logging Admin:", error);
  }
};
export const getAdmin = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/user/1`
    );
    if (response?.data?.isSuccess) {
      return response;
    } else {
      console.error("Failed to get Admin:", response?.data?.message);
    }
  } catch (error) {
    console.error("Error getting Admin:", error);
  }
};
export const updateAdmin = async (body) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_BASE_URL}/user/1`,
      body
    );
    if (response?.data?.isSuccess) {
      return response;
    } else {
      console.error("Failed to update Admin:", response?.data?.message);
    }
  } catch (error) {
    console.error("Error updating Admin:", error);
  }
};
export const fetchAllScenes = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/scene/all?limit=10000`,
      config
    );
    if (response?.data?.isSuccess) {
      return response;
    } else {
      console.error("Failed to fetch scenes:", response?.data?.message);
    }
  } catch (error) {
    console.error("Error fetching scenes:", error);
  }
};

export const CreateScene = async (body) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/scene/create`,
      body,
      config
    );
    if (response?.data?.isSuccess) {
      return response;
    } else {
      console.error("Failed to create scene:", response?.data?.message);
    }
  } catch (error) {
    console.error("Error creating scene data:", error);
  }
};
export const EditScene = async (body, id) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_BASE_URL}/scene/${id}`,
      body,
      config
    );
    if (response?.data?.isSuccess) {
      return response;
    } else {
      console.error("Failed to edit scene:", response?.data?.message);
    }
  } catch (error) {
    console.error("Error editing scene data:", error);
  }
};
export const fetchSingleScene = async (id) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/scene/${id}`,
      config
    );
    if (response?.data?.isSuccess) {
      return response;
    } else {
      console.error("Failed to fetch scene:", response?.data?.message);
    }
  } catch (error) {
    console.error("Error fetching scene data:", error);
  }
};
export const DeleteScene = async (id) => {
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_BASE_URL}/scene/${id}`,
      config
    );
    if (response?.data?.isSuccess) {
      return response;
    } else {
      console.error("Failed to delete scene:", response?.data?.message);
    }
  } catch (error) {
    console.error("Error deleting scene data:", error);
  }
};
export const DeleteAuthor = async (id) => {
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_BASE_URL}/author/${id}`,
      config
    );
    if (response?.data?.isSuccess) {
      return response;
    } else {
      console.error("Failed to delete author:", response?.data?.message);
    }
  } catch (error) {
    console.error("Error deleting author data:", error);
  }
};