import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormGroup,
  FormInput,
  FormSelect,
  FormTextarea,
  Button,
} from "shards-react";
import { getAdmin, updateAdmin } from "../../Api/helpers";
import { useNavigate } from "react-router-dom";

const UserAccountDetails = ({ title }) => {
  const initialFormData = {
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    username: "",
    phone: "",
  };
  const [formData, setFormData] = useState(initialFormData);
  const navigate = useNavigate();
  useEffect(() => {
    const getData = async () => {
      let response = await getAdmin();
      setFormData({
        first_name: response?.data?.data?.first_name,
        last_name: response?.data?.data?.last_name,
        email: response?.data?.data?.email,
        username: response?.data?.data?.username,
        phone: response?.data?.data?.phone,
      });
    };
    getData();
  }, []);
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  };
  const handleUpdate = async () => {
    let response = await updateAdmin(formData);
    console.log("data updated: ", response);
    navigate(-1);
  };
  return (
    <Card className="mb-4">
      <CardHeader className="border-bottom">
        <h6 className="m-0">{title}</h6>
      </CardHeader>
      <ListGroup flush>
        <ListGroupItem className="p-3">
          <Row>
            <Col>
              <Form onSubmit={handleUpdate}>
                <Row form>
                  {/* First Name */}
                  <Col md="6" className="form-group">
                    <label htmlFor="feFirstName">First Name</label>
                    <FormInput
                      name="first_name"
                      placeholder="First Name"
                      value={formData?.first_name}
                      onChange={handleChange}
                      required
                    />
                  </Col>
                  {/* Last Name */}
                  <Col md="6" className="form-group">
                    <label htmlFor="feLastName">Last Name</label>
                    <FormInput
                      name="last_name"
                      placeholder="Last Name"
                      value={formData?.last_name}
                      onChange={handleChange}
                      required
                    />
                  </Col>
                </Row>
                <Row form>
                  {/* Email */}
                  <Col md="6" className="form-group">
                    <label htmlFor="feEmail">Email</label>
                    <FormInput
                      type="email"
                      placeholder="Email Address"
                      value={formData?.email}
                      autoComplete="email"
                      readOnly
                    />
                  </Col>
                  {/* Password */}
                  <Col md="6" className="form-group">
                    <label htmlFor="fePassword">Password</label>
                    <FormInput
                      type="password"
                      name="password"
                      placeholder="Password"
                      value={formData?.password}
                      onChange={handleChange}
                      autoComplete="current-password"
                    />
                  </Col>
                </Row>
                <Row form>
                  {/* Email */}
                  <Col md="6" className="form-group">
                    <label htmlFor="feEmail">Username</label>
                    <FormInput
                      name="username"
                      placeholder="Username"
                      value={formData?.username}
                      onChange={handleChange}
                      required
                    />
                  </Col>
                  {/* Password */}
                  {/* <Col md="6" className="form-group">
                    <label htmlFor="fePassword">Phone Number</label>
                    <FormInput
                      name="phone"
                      type="tel"
                      placeholder="Phone Number"
                      value={formData?.phone}
                      onChange={handleChange}
                      autoComplete="current-password"
                    />
                  </Col> */}
                </Row>
                <Button theme="accent">
                  Update Account
                </Button>
              </Form>
            </Col>
          </Row>
        </ListGroupItem>
      </ListGroup>
    </Card>
  );
};

UserAccountDetails.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string,
};

UserAccountDetails.defaultProps = {
  title: "Account Details",
};

export default UserAccountDetails;
