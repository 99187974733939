import React from "react";
import { Container, Row, Col } from "shards-react";

import PageTitle from "../../components/common/PageTitle";
import Editor from "../../components/add-new-album/Editor";
import { useParams } from "react-router-dom";

const AddNewAlbum = () => {
  const { id } = useParams();
  return (
    <Container fluid className="main-content-container px-4 pb-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle sm="4" title={`${id ? "Edit Album" : "Add New Album" }`} className="text-sm-left" />
      </Row>

      <Row>
        {/* Editor */}
        <Col lg="12" md="12">
          <Editor />
        </Col>
      </Row>
    </Container>
  );
};

export default AddNewAlbum;
