import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse,
  NavItem,
  NavLink,
} from "shards-react";
import { useAuth } from "../../../../Context/AuthContext";
import { getAdmin } from "../../../../Api/helpers";

const UserActions = () => {
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();
  const { logout } = useAuth();

  const toggleUserActions = () => {
    setVisible(!visible);
  };

  const handleLogout = () => {
    // Remove the token from local storage
    localStorage.clear();
    // Redirect to the login page
    logout();
    navigate("/login");
  };

  const initialFormData = {
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    username: "",
    phone: "",
  };
  const [formData, setFormData] = useState(initialFormData);
  useEffect(() => {
    const getData = async () => {
      let response = await getAdmin();
      setFormData({
        first_name: response?.data?.data?.first_name,
        last_name: response?.data?.data?.last_name,
        email: response?.data?.data?.email,
        username: response?.data?.data?.username,
        phone: response?.data?.data?.phone,
      });
    };
    getData();
  }, []);

  return (
    <NavItem tag={Dropdown} caret toggle={toggleUserActions}>
      <DropdownToggle caret tag={NavLink} className="text-nowrap px-3">
        <span
          className="d-none d-md-inline-block"
          style={{
            cursor: "pointer",
            marginTop: "5px",
            fontSize: "20px",
            fontWeight: "bold",
          }}
        >
          {formData.first_name + " " + formData.last_name}
        </span>
      </DropdownToggle>
      <Collapse tag={DropdownMenu} right small open={visible}>
        <DropdownItem tag={Link} to="/user-profile-lite">
          <i className="material-icons">&#xE8B8;</i> Edit Profile
        </DropdownItem>
        <DropdownItem divider />
        <DropdownItem
          tag={Link}
          to="/login"
          className="text-danger"
          onClick={handleLogout}
        >
          <i className="material-icons text-danger">&#xE879;</i> Logout
        </DropdownItem>
      </Collapse>
    </NavItem>
  );
};

export default UserActions;
