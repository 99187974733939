// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-icon{
    display: flex;
    gap: 10px;
}
.table-icon img:hover{
    cursor: pointer;
}
.custom-btn{
    font-size: 1rem !important;
    padding: 1rem !important;
}
.small-input {
  width: 60px !important;
}

@media only screen and (max-width: 580px) {
    .custom-page-header{
        /* justify-content: space-around; */
        flex-direction: column !important;
        align-items: center;
        gap: 10px;
    }
    /* .custom-page-header .custom-btn {
        max-width: 150px;
    } */
  }
  .pagination {
    margin-top: 80px;
    margin-bottom: 80px;
    justify-content: center;
    display: flex;
    /* align-items: center; */
    gap: 5px;
  }

  .custom-modal {
    max-width: 400px !important; /* Set maximum width to 1000px */
    /* width: 100% !important; Ensure modal takes full width of the viewport */
    margin: auto; /* Center align horizontally */
  }
  
  /* .modal-content {
    height: 250px; 
  } */
  
  .modal-backdrop.show {
    opacity: 0.3 !important; /* Lower background opacity */
  }
  `, "",{"version":3,"sources":["webpack://./src/components/common/table.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,SAAS;AACb;AACA;IACI,eAAe;AACnB;AACA;IACI,0BAA0B;IAC1B,wBAAwB;AAC5B;AACA;EACE,sBAAsB;AACxB;;AAEA;IACI;QACI,mCAAmC;QACnC,iCAAiC;QACjC,mBAAmB;QACnB,SAAS;IACb;IACA;;OAEG;EACL;EACA;IACE,gBAAgB;IAChB,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;IACb,yBAAyB;IACzB,QAAQ;EACV;;EAEA;IACE,2BAA2B,EAAE,gCAAgC;IAC7D,0EAA0E;IAC1E,YAAY,EAAE,8BAA8B;EAC9C;;EAEA;;KAEG;;EAEH;IACE,uBAAuB,EAAE,6BAA6B;EACxD","sourcesContent":[".table-icon{\n    display: flex;\n    gap: 10px;\n}\n.table-icon img:hover{\n    cursor: pointer;\n}\n.custom-btn{\n    font-size: 1rem !important;\n    padding: 1rem !important;\n}\n.small-input {\n  width: 60px !important;\n}\n\n@media only screen and (max-width: 580px) {\n    .custom-page-header{\n        /* justify-content: space-around; */\n        flex-direction: column !important;\n        align-items: center;\n        gap: 10px;\n    }\n    /* .custom-page-header .custom-btn {\n        max-width: 150px;\n    } */\n  }\n  .pagination {\n    margin-top: 80px;\n    margin-bottom: 80px;\n    justify-content: center;\n    display: flex;\n    /* align-items: center; */\n    gap: 5px;\n  }\n\n  .custom-modal {\n    max-width: 400px !important; /* Set maximum width to 1000px */\n    /* width: 100% !important; Ensure modal takes full width of the viewport */\n    margin: auto; /* Center align horizontally */\n  }\n  \n  /* .modal-content {\n    height: 250px; \n  } */\n  \n  .modal-backdrop.show {\n    opacity: 0.3 !important; /* Lower background opacity */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
