import React from "react";
import { Col, Container, Row } from "shards-react";
import PageTitle from "../../components/common/PageTitle";
import AuthorEditor from "../../components/add-new-author/AuthorEditor";
import { useParams } from "react-router-dom";

const AddAuthor = () => {
  const { id } = useParams();
  return (
    <Container fluid className="main-content-container px-4 mb-4">
      {/* Page Header */}
      <Row
        noGutters
        className="page-header py-4 justify-content-between custom-page-header"
      >
        <PageTitle title={id ? "Edit Author" : "Add Author"} className="text-sm-left" />
      </Row>

      {/* Content Section */}
      <Row>
        <Col lg="12" md="12">
          <AuthorEditor />
        </Col>
      </Row>
    </Container>
  );
};

export default AddAuthor;