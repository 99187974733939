import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Row } from "shards-react";
import PageTitle from "../../components/common/PageTitle";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { SavePolicy, getPolicy } from "../../Api/helpers";
import { htmlToEditorState } from "../../components/common/htmlToEditor";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export const TermsAndConditions = () => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const onEditorStateChange = (newState) => {
    setEditorState(newState);
  };

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const response = await getPolicy("terms and conditions");
      const content = response?.data?.data?.content;
      if (content) {
        const data = htmlToEditorState(content);
        setEditorState(data);
      }
    };

    fetchData();
  }, []);

  const handleSave = async () => {
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    let htmlContent = draftToHtml(rawContentState);
    // console.log("htmlContent before", htmlContent);
    htmlContent = htmlContent.replace(/<p><\/p>/g, '<br/>');
    // console.log("htmlContent after", htmlContent);
    const response = await SavePolicy({
      content: htmlContent,
      category: "terms and conditions",
    });
    if (response.data.isSuccess) {
      toast.success("Terms & Conditions updated successfully!");
      // navigate(-1);
    }
  };

  return (
    <>
      <Container fluid className="main-content-container px-4">
        {/* Page Header */}
        <Row
          noGutters
          className="page-header py-4 justify-content-between custom-page-header"
        >
          <PageTitle title="Terms and Conditions" className="text-sm-left" />
        </Row>

        {/* Content Section */}
        <Row>
          <Col>
            <Card>
              <div className="p-4">
                <Editor
                  editorState={editorState}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="custom-editor-class"
                  onEditorStateChange={onEditorStateChange}
                  stripPastedStyles={true}
                />
              </div>
              <div className="px-4 pb-4">
                <Button onClick={handleSave}>Save</Button>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};
