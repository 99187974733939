export default function () {
  return [
    // {
    //   title: "Add New Album",
    //   htmlBefore: '<i class="material-icons">note_add</i>',
    //   to: "/add-new-album",
    // },
    {
      title: "Categories",
      htmlBefore: '<i class="material-icons">table_chart</i>',
      to: "/categories",
    },
    {
      title: "Tracks",
      htmlBefore: '<i class="material-icons">table_chart</i>',
      to: "/tracks",
    },
    {
      title: "Albums",
      htmlBefore: '<i class="material-icons">table_chart</i>',
      to: "/albums",
    },
    
    {
      title: "Authors",
      htmlBefore: '<i class="material-icons">person</i>',
      to: "/author",
    },
    {
      title: "Scenes",
      htmlBefore: '<i class="material-icons">table_chart</i>',
      to: "/scenes",
    },
    // {
    //   title: "User Profile",
    //   htmlBefore: '<i class="material-icons">person</i>',
    //   to: "/user-profile-lite",
    // },
    {
      title: "Privacy Policy",
      htmlBefore: '<i class="material-icons">table_chart</i>',
      to: "/privacy-policy",
    },
    {
      title: "Terms and Conditions",
      htmlBefore: '<i class="material-icons">table_chart</i>',
      to: "/terms-and-conditions",
    },
  ];
}
