import React, { useEffect, useState } from "react";
import CustomTable from "../../components/common/CommonTable";
import { useNavigate } from "react-router-dom";
import { DeleteScene, fetchAllScenes } from "../../Api/helpers";

export const Scenes = () => {
  const navigate = useNavigate();
  const [scenes, setScenes] = useState();

  const handleButtonClick = () => {
    navigate(`/add-scenes`);
  };

  const handleDelete = async (id) => {
    try {
      const response = await DeleteScene(id);
      if (response?.data?.isSuccess) {
        setScenes((prevScenes) =>
          prevScenes?.filter((scene) => scene?.id !== id)
        );
        console.log(`Scene with id ${id} deleted successfully`);
      } else {
        console.error("Error deleting Scene:", response?.data?.message);
      }
    } catch (error) {
      console.error("Error deleting Scene:", error);
    }
  };

  const tableHeaders = ["#", "Title", "Track", "Order", "Image"];

  const handleEdit = (id) => {
    navigate(`/edit-scenes/${id}`);
  };

  const fetchScenes = async () => {
    try {
      const response = await fetchAllScenes();
      if (response?.data?.isSuccess) {
        const scenesTableData = response?.data?.data?.rows?.map(
          (scene, index) => ({
            "#": index + 1,
            Title: scene?.name,
            Track: scene?.file_path,
            Order:scene?.order,
            Image: scene?.image,
            // IsPaid: scene?.IsPaid,
            Actions: "",
            id: scene?.id,
          })
        );
        console.log("to be put in usestate ", response.data);
        setScenes(scenesTableData);
      } else {
        console.error("Failed to fetch scenes:", response?.data?.message);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  console.log("scenes fetched: ++++++ ", scenes);
  useEffect(() => {
    fetchScenes();
  }, []);

  return (
    <CustomTable
      pageTitle={"Scenes"}
      buttonText={"Add New Scene"}
      handleDelete={handleDelete}
      handleEdit={handleEdit}
      cardHeader={"Scenes List"}
      data={scenes}
      tableHeaders={tableHeaders}
      handleButtonClick={handleButtonClick}
    />
  );
};
