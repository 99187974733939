// src/App.js
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import routes from "./routes";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/shards-dashboards.1.1.0.min.css";
// import { AuthProvider } from "./context/AuthContext";
import { AuthProvider } from "./Context/AuthContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const App = () => {
  return (
    <AuthProvider>
      <Router basename={process.env.REACT_APP_BASENAME || ""}>
        <div>
          <Routes>
            {routes.map((route, index) => (
              <Route key={index} path={route.path} element={route.element} />
            ))}
          </Routes>
        </div>
      </Router>

      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </AuthProvider>
  );
};
export default App;

// src/App.js
// import React from "react";
// import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import routes from "./routes";
// import './App.css';
// import "bootstrap/dist/css/bootstrap.min.css";
// import "./styles/shards-dashboards.1.1.0.min.css";
// import { AuthProvider } from "./Context/AuthContext";
// import RedirectRoute from "./components/RedirectRoute";

// const App = () => (
//   <AuthProvider>
//     <Router basename={process.env.REACT_APP_BASENAME || ""}>
//       <Routes>
//         {routes.map((route, index) => (
//           <Route
//             key={index}
//             path={route.path}
//             element={route.redirectTo ? <RedirectRoute element={route.element} redirectTo={route.redirectTo} /> : route.element}
//           />
//         ))}
//       </Routes>
//     </Router>
//
//   </AuthProvider>
// );

// export default App;
