import React from 'react'
import { Col, Container, Row } from 'shards-react';
import PageTitle from '../../components/common/PageTitle';
import SceneEditor from '../../components/scene-editor/SceneEditor';
import { useParams } from 'react-router-dom';

export const AddScenes = () => {
  const {id} = useParams();

    return (
        <Container fluid className="main-content-container px-4 mb-4">
          {/* Page Header */}
          <Row
            noGutters
            className="page-header py-4 justify-content-between custom-page-header"
          >
            <PageTitle title={id ? "Edit Scene" : "Add Scene"} className="text-sm-left" />
          </Row>
    
          {/* Content Section */}
          <Row>
            <Col lg="12" md="12">
              <SceneEditor />
            </Col>
          </Row>
        </Container>
      );
}
