import React, { useEffect, useState } from "react";
import CustomTable from "../../components/common/CommonTable";
import { useNavigate } from "react-router-dom";
import { DeleteAuthor, fetchAllAuthors } from "../../Api/helpers";

const Author = () => {
  const [authors, setAuthors] = useState([]);
  const navigate = useNavigate();
  const handleEdit = (id) => {
    navigate(`/edit-author/${id}`);
  };

  const fetchAuthors = async () => {
    try {
      const response = await fetchAllAuthors();
      if (response?.data?.isSuccess) {
        const authorsTableData = response?.data?.data?.rows?.map(
          (author, index) => ({
            "#": index + 1,
            Name: author?.name,
            Bio: author?.bio,
            Image: author?.image,
            Actions: "",
            id: author?.id
          })
        );
        setAuthors(authorsTableData);
      } else {
        console.error("Failed to fetch authors:", response?.data?.message);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  useEffect(() => {
    fetchAuthors();
  }, []);

  const tableHeaders = ["#", "Name", "Bio", "Image"];

  const handleButtonClick = () => {
    navigate(`/add-new-author`);
  };
  const handleDelete = async (id) => {
    try {
      const response = await DeleteAuthor(id);
      if (response?.data?.isSuccess) {
        setAuthors((prevAuthors) =>
          prevAuthors?.filter((author) => author?.id !== id)
        );
        console.log(`Author deleted successfully`);
      } else {
        console.error("Error deleting author:", response?.data?.message);
      }
    } catch (error) {
      console.error("Error deleting author:", error);
    }
  };
  return (
    <CustomTable
      pageTitle={"Authors"}
      buttonText={"Add New Author"}
      handleDelete={handleDelete}
      handleEdit={handleEdit}
      cardHeader={"Author List"}
      data={authors}
      tableHeaders={tableHeaders}
      handleButtonClick={handleButtonClick}
    />
  );
};
export default Author;
