import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Form,
  FormInput,
  FormGroup,
  Button,
  FormTextarea,
  FormSelect,
} from "shards-react";
import "../add-new-album/editorStyle.css";
import { useNavigate, useParams } from "react-router-dom";
import {
  CreateCategory,
  EditCategory,
  fetchAllCategories,
  fetchSingleCategory,
} from "../../Api/helpers";
import { toast } from "react-toastify";

const CategoryEditor = () => {
  // State variables for form inputs
  const navigate = useNavigate();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [count, setCount] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    // description: "",
    parentCategoryId: "",
    order: "",
  });
  const [categories, setCategories] = useState([]);
  // console.log("idid", id);
  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isLoading) return;
    setIsLoading(true);
    const body = {
      name: formData?.name,
      description: formData?.description,
      // parent_category_id: formData?.parentCategoryId,
    };
    if (formData?.parentCategoryId) {
      body.parent_category_id = formData?.parentCategoryId;
    }
    if (formData?.order) {
      body.order = formData?.order;
    }
    console.log(formData, body);
    try {
      let response;
      if (id) {
        response = await EditCategory(body, id);
      } else {
        response = await CreateCategory(body);
      }
      if (!response) {
        toast.error("Could not create this Category");
      }
      // console.log("this this", response);
      if (response?.data) {
        navigate(-1);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const fetchData = async () => {
    try {
      const response = await fetchSingleCategory(id);
      const albumData = response?.data?.data?.categoryData;
      setCount(response?.data?.data?.count);
      setFormData({
        name: albumData?.name,
        description: albumData?.description,
        parentCategoryId: albumData?.parent_category_id,
        order: albumData?.order,
      });
    } catch (error) {
      console.error("Error fetching album data:", error);
    }
  };

  useEffect(() => {
    if (id) {
      fetchData();
    }
  }, [id]);

  const fetchCategories = async (categoryId) => {
    const response = await fetchAllCategories();
    if (response?.data?.isSuccess) {
      const data = response?.data?.data?.rows;
      const filteredData = data.filter((item) => item.id != categoryId);
      setCategories(filteredData);
    }
  };

  useEffect(() => {
    fetchCategories(id);
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <Card small className="mb-3">
      <CardBody>
        <Form onSubmit={handleSubmit}>
          <div className="add-new-post row">
            <FormGroup className="col-md-6">
              <label htmlFor="name">Name</label>
              <FormInput
                name="name"
                value={formData?.name}
                onChange={handleChange}
                placeholder="Name"
                required
              />
            </FormGroup>
            <FormGroup className="col-md-6">
              <label htmlFor="parentCategoryId">Category</label>
              <FormSelect
                disabled={id ? true : false}
                name="parentCategoryId"
                value={formData?.parentCategoryId}
                onChange={handleChange}
                placeholder="Category"
                // required
              >
                <option value="" disabled>
                  Select a category
                </option>
                {categories.map(
                  (category) =>
                    !category.parent_category_id && (
                      <option key={category.id} value={category.id}>
                        {category.name}
                      </option>
                    )
                )}
              </FormSelect>
            </FormGroup>
            <FormGroup className="col-md-6">
              <label htmlFor="description">Description</label>
              <FormTextarea
                name="description"
                type="textarea"
                value={formData?.description}
                onChange={handleChange}
                placeholder="Description"
                // required
              />
            </FormGroup>
            {id && (
              <FormGroup className="col-md-6">
                <label htmlFor="order">Order</label>
                <FormInput
                  name="order"
                  value={formData?.order}
                  onChange={handleChange}
                  placeholder="Order"
                  required={id ? true : false}
                  type="Number"
                  min="1"
                  max={count}
                />
              </FormGroup>
            )}
          </div>
          <Button
            theme="primary"
            type="submit"
            className="custom-button"
            disabled={isLoading}
          >
            {isLoading ? "Submitting..." : "Submit"}
          </Button>
        </Form>
      </CardBody>
    </Card>
  );
};

export default CategoryEditor;
