import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import { FaEdit, FaTrash } from 'react-icons/fa';

import CustomTable from "../../components/common/CommonTable";
import { DeleteAlbum, fetchAllAlbums } from "../../Api/helpers";

const AlbumTableList = () => {
  const navigate = useNavigate();
  const [albums, setAlbums] = useState([]);
  const handleEdit = (id) => {
    navigate(`/edit-album/${id}`);
  };
  const handleButtonClick = () => {
    navigate(`/add-new-album`);
  };

  const handleDelete = async (id) => {
    try {
      const response = await DeleteAlbum(id);
      if (response?.data?.isSuccess) {
        setAlbums((prevAlbums) =>
          prevAlbums?.filter((album) => album?.id !== id)
        );
        console.log(`Album with id ${id} deleted successfully`);
      } else {
        console.error("Error deleting album:", response?.data?.message);
      }
    } catch (error) {
      console.error("Error deleting album:", error);
    }
  };

  const fetchAlbums = async () => {
    try {
      const response = await fetchAllAlbums();
      if (response?.data?.isSuccess) {
        const albumsTableData = response?.data?.data?.rows?.map(
          (album, index) => ({
            "#": index + 1,
            Name: album?.title,
            Description: album?.long_description,
            "Short Description": album?.short_description,
            Author: album?.author?.name,
            Order:album?.order,
            Image: album?.image,
            Category: album?.category?.name,
            Actions: "",
            id: album?.id,
          })
        );
        setAlbums(albumsTableData);
      } else {
        console.error("Failed to fetch albums:", response?.data?.message);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  useEffect(() => {
    fetchAlbums();
  }, []);
  const tableHeaders = [
    "#",
    "Name",
    "Description",
    "Short Description",
    "Author",
    "Order",
    "Image",
    "Category",
  ];
  //   { pageTitle, buttonText, cardHeader, data, tableHeaders, handleDelete, handleEdit, handleButtonClick }
  return (
    <CustomTable
      pageTitle={"Albums"}
      buttonText={"Add New Albums"}
      handleDelete={handleDelete}
      handleEdit={handleEdit}
      cardHeader={"Album List"}
      data={albums}
      tableHeaders={tableHeaders}
      handleButtonClick={handleButtonClick}
      isViewable={true}
      link={"/tracks"}
    />
  );
};

export default AlbumTableList;
