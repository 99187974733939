import React from "react";
import { Container, Row, Col } from "shards-react";

import PageTitle from "../../components/common/PageTitle";
import { useParams } from "react-router-dom";
import TrackEditor from "../../components/track-editor/TrackEditor";

const TrackUpsert = () => {
  const { id } = useParams();
  return (
    <Container fluid className="main-content-container px-4 pb-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle sm="4" title={`${id ? "Edit Track" : "Add New Track" }`} className="text-sm-left" />
      </Row>

      <Row>
        {/* Editor */}
        <Col lg="12" md="12">
          <TrackEditor />
        </Col>

        {/* Sidebar Widgets */}
        {/* <Col lg="3" md="12">
        <SidebarActions />
        <SidebarCategories />
      </Col> */}
      </Row>
    </Container>
  );
};

export default TrackUpsert;
