import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  InputGroup,
  FormInput,
  FormSelect,
} from "shards-react";
import "./table.css";
import PageTitle from "./PageTitle";
import { FaEdit, FaTrash } from "react-icons/fa";
import { GrFormView } from "react-icons/gr";
import { truncateText } from "./TextCheck";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { fetchAllCategories } from "../../Api/helpers";

const CustomTable = ({
  pageTitle,
  buttonText,
  cardHeader,
  data,
  tableHeaders,
  handleDelete,
  handleEdit,
  handleButtonClick,
  isViewable,
  link,
  parentCategory,
}) => {
  const AlbumId = useParams();
  const [selectedOption, setSelectedOption] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(data?.length + 60);
  const [searchQuery, setSearchQuery] = useState("");
  const [parentCategories, setParentCategories] = useState([]);
  const itemsPerPage = 10;
  const totalPages = Math.ceil(count / itemsPerPage);
  const navigate = useNavigate();
  const location = useLocation();

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const filteredData = data?.filter((album) => {
    const matchesSearchQuery = album.Name
      ? album.Name.toLowerCase().includes(searchQuery.toLowerCase())
      : album.Title.toLowerCase().includes(searchQuery.toLowerCase());

    const matchesCategory = selectedOption
      ? album.category_id == selectedOption
      : true;

    return matchesSearchQuery && matchesCategory;
  });

  const getCategoriesData = async () => {
    const response = await fetchAllCategories();
    setParentCategories(
      response?.data?.data?.rows.filter((item) => item?.parent_category_id != null)
    );
  };

  useEffect(() => {
    getCategoriesData();
  }, []);

  return (
    <>
      <Container fluid className="main-content-container px-4">
        {/* Page Header */}
        <Row
          noGutters
          className="page-header py-4 justify-content-between custom-page-header"
        >
          <PageTitle title={pageTitle} className="text-sm-left" />
          <Button
            theme="primary"
            className="custom-btn"
            onClick={handleButtonClick}
          >
            {buttonText}
          </Button>
        </Row>

        {/* Default Light Table */}
        <Row>
          <Col>
            <Card small className="mb-4">
              <CardHeader className="border-bottom">
                <Row className="justify-content-between align-items-center">
                  <Col>
                    <h6 className="m-0">{cardHeader}</h6>
                  </Col>
                  {location.pathname.includes("tracks") && (
                      <Col className="text-right">
                        <InputGroup>
                          <FormInput
                            placeholder="Search by Name"
                            value={searchQuery}
                            onChange={handleSearch}
                            className="small-input"
                          />
                        </InputGroup>
                      </Col>
                      )}
                      {!AlbumId.id && location.pathname.includes("tracks") && (
                      <Col className="text-right">
                        <InputGroup>
                          <FormSelect
                            value={selectedOption}
                            onChange={handleSelectChange}
                          >
                            <option value="">All Categories</option>
                            {parentCategories.map((option) => (
                              <option key={option.id} value={option.id}>
                                {option.name}
                              </option>
                            ))}
                          </FormSelect>
                        </InputGroup>
                      </Col>
                      )}
                  
                </Row>
              </CardHeader>
              <CardBody className="p-0 pb-3 overflow-auto">
                <table className="table mb-0">
                  <thead className="bg-light">
                    <tr>
                      {tableHeaders?.map((header, index) => (
                        <th key={index} scope="col" className="border-0">
                          {header}
                        </th>
                      ))}
                      <th scope="col" className="border-0">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredData?.map((album, index) => (
                      <tr key={album?.id}>
                        {tableHeaders?.map((header) => (
                          <td key={header}>
                            {header === "#" ? (
                              index + 1
                            ) : header === "Parent Category" ? (
                              parentCategory
                            ) : header === "Image" ? (
                              <img
                                src={album[header]}
                                alt="Album Image"
                                style={{ width: "50px" }}
                              />
                            ) : header === "Track" ? (
                              <audio controls key={`${album[header]}_${index}`}>
                                <source src={album[header]} type="audio/mp3" />
                                Your browser does not support the audio element.
                              </audio>
                            ) : (
                              truncateText(album[header], 15)
                            )}
                          </td>
                        ))}
                        <td>
                          <div className="table-icon">
                            <div
                              style={{
                                backgroundColor: "#007bfe",
                                borderRadius: "30%",
                                width: "40px",
                                height: "40px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                color: "white",
                              }}
                            >
                              <FaEdit
                                size={20}
                                onClick={() => handleEdit(album?.id)}
                                style={{ color: "white", cursor: "pointer" }}
                              />
                            </div>
                            <div
                              style={{
                                backgroundColor: "red",
                                borderRadius: "30%",
                                width: "40px",
                                height: "40px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                color: "white",
                              }}
                            >
                              <FaTrash
                                size={20}
                                onClick={() => handleDelete(album?.id)}
                                style={{ color: "white", cursor: "pointer" }}
                              />
                            </div>
                            {isViewable && (
                              <div
                                style={{
                                  backgroundColor: "#007bfe",
                                  borderRadius: "30%",
                                  width: "40px",
                                  height: "40px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  color: "white",
                                }}
                              >
                                <GrFormView
                                  size={30}
                                  onClick={() => {
                                    navigate(`${link}/${album.id}`, {
                                      state: { name: album.Name },
                                    });
                                  }}
                                  style={{ color: "white", cursor: "pointer" }}
                                />
                              </div>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CustomTable;
