import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
// import { FaEdit, FaTrash } from 'react-icons/fa';
import CustomTable from "../../components/common/CommonTable";
import {
  DeleteCategory,
  fetchAllCategories,
  fetchSingleCategory,
} from "../../Api/helpers";

const CategoryTableList = () => {
  const location = useLocation();
  const { name } = location.state || {};
  const data = useParams();
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const handleEdit = (id) => {
    navigate(`/edit-category/${id}`);
  };
  const handleButtonClick = () => {
    navigate(`/add-new-category`);
  };

  const handleDelete = async (id) => {
    try {
      const response = await DeleteCategory(id);
      if (response?.data?.isSuccess) {
        setCategories((prevAlbums) =>
          prevAlbums?.filter((album) => album?.id !== id)
        );
        console.log(`Category with id ${id} deleted successfully`);
      } else {
        console.error("Error deleting Category:", response?.data?.message);
      }
    } catch (error) {
      console.error("Error deleting Category:", error);
    }
  };

  // const fetchAlbums = async () => {
  //   try {
  //     const response = await fetchAllCategories();
  //     console.log("dtaa received checing... ", response)
  //     if (response?.data?.isSuccess) {
  //       const albumsTableData = response?.data?.data?.rows?.map(
  //         (category, index) => ({
  //           "#": index + 1,
  //           Name: category.name,
  //           Description: category.description,
  //           "Parent Category": fetchSingleCategory(category.parent_category_id) || "N/A",
  //           id: category?.id,
  //         })
  //       );
  //       setCategories(albumsTableData);
  //     } else {
  //       console.error("Failed to fetch categories:", response?.data?.message);
  //     }
  //   } catch (error) {
  //     console.error("Error:", error);
  //   }
  // };

  const fetchAlbums = async () => {
    try {
      const response = await fetchAllCategories();
      // console.log("123456789    ", response?.data?.data?.rows);
      if (data?.id) {
        const result = response?.data?.data?.rows.filter(
          (category) => category?.id == data?.id
        );
        // console.log("i have fetched this one :::: ", result[0]?.subCategories);

        const categoriesWithParent = await Promise.all(
          result[0]?.subCategories.map(async (category, index) => {
            let parentCategoryName = "N/A";

            return {
              "#": index + 1,
              Name: category.name,
              Description: category.description,
              Order: category.order,
              "Parent Category": category.parent_category_id,
              id: category?.id,
            };
          })
        );

        // setCategories(categoriesWithParent);
        const sortedCategories = categoriesWithParent.sort(
          (a, b) => a.Order - b.Order
        );
        setCategories(sortedCategories);
      } else {
        if (response?.data?.isSuccess) {
          let categories = [];
          // const categoriesWithParent = await Promise.all(
          //   response?.data?.data?.rows?.map(async (category, index) => {
          //     let parentCategoryName = "N/A";
          //     if (category.parent_category_id) {
          //       const parentResponse = await fetchSingleCategory(
          //         category.parent_category_id
          //       );
          //       if (parentResponse?.data?.isSuccess) {
          //         parentCategoryName = parentResponse?.data?.data?.name;
          //       }
          //     }
          //     // if (!category.parent_category_id) {
          //     //   categories.push(category);
          //     // }
          //     // console.log("category.....     ", categories);
          //     return {
          //       "#": index + 1,
          //       Name: category.name,
          //       Description: category.description,
          //       "Parent Category": parentCategoryName,
          //       id: category?.id,
          //     };
          //   })
          // );
          const filteredCategories = response?.data?.data?.rows?.filter(
            (category) => category.parent_category_id === null
          );

          const categoriesWithParent = await Promise.all(
            filteredCategories.map(async (category, index) => {
              let parentCategoryName = "N/A";

              return {
                "#": index + 1,
                Name: category.name,
                Description: category.description,
                Order: category.order,
                "Parent Category": parentCategoryName,
                id: category?.id,
              };
            })
          );

          setCategories(categoriesWithParent);
          // setCategories(categories);
        } else {
          console.error("Failed to fetch categories:", response?.data?.message);
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  useEffect(() => {
    fetchAlbums();
  }, [data?.id]);
  // const tableHeaders = ["#", "Name", "Description", "Order", "Parent Category"];
  const tableHeaders = ["#", "Name", "Order"];
  if (data?.id) {
    tableHeaders.push("Parent Category");
  }
  //   { pageTitle, buttonText, cardHeader, data, tableHeaders, handleDelete, handleEdit, handleButtonClick }
  return (
    <CustomTable
      pageTitle={"Categories"}
      buttonText={"Add New Category"}
      handleDelete={handleDelete}
      handleEdit={handleEdit}
      cardHeader={"Category List"}
      data={categories}
      tableHeaders={tableHeaders}
      handleButtonClick={handleButtonClick}
      isViewable={!data.id}
      link={"/categories"}
      parentCategory={name}
    />
  );
};

export default CategoryTableList;
