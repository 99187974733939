import htmlToDraft from "html-to-draftjs";
import { EditorState, ContentState } from "draft-js";

export function htmlToEditorState(html) {
  const contentBlock = htmlToDraft(html);
  if (contentBlock) {
    const contentState = ContentState.createFromBlockArray(
      contentBlock.contentBlocks
    );
    return EditorState.createWithContent(contentState);
  }
}
